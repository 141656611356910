import React, { memo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const OurCapabilties = () => {
  const data = useStaticQuery(graphql`
    query OurCapabilitiesPanelQuery {
      content: contentfulOurCapabilitiesPanel {
        title
        section1Title
        section1Items
        section2Title
        section2Items
        section3Title
        section3Items
        section4Title
        section4Items
      }
    }
  `);

  const {
    content: {
      title,
      section1Title,
      section1Items,
      section2Title,
      section2Items,
      section3Title,
      section3Items,
      section4Title,
      section4Items,
    },
  } = data;

  return (
    <section className="panel panel--inset panel--our-capabilities our-capabilities">
      <h2 className="our-capabilities__title">{title}</h2>

      <div className="our-capabilities__grid">
        <div className="our-capabilities__section">
          <h3 className="our-capabilities__section-title">{section1Title}</h3>
          <ol className="our-capabilities__section-items">
            {section1Items.map((item) => (
              <li className="our-capabilities__item" key={`Section 1 ${item}`}>
                {item}
              </li>
            ))}
          </ol>
        </div>

        <div className="our-capabilities__section">
          <h3 className="our-capabilities__section-title">{section2Title}</h3>
          <ol className="our-capabilities__section-items">
            {section2Items.map((item) => (
              <li className="our-capabilities__item" key={`Section 2 ${item}`}>
                {item}
              </li>
            ))}
          </ol>
        </div>

        <div className="our-capabilities__section">
          <h3 className="our-capabilities__section-title">{section3Title}</h3>
          <ol className="our-capabilities__section-items">
            {section3Items.map((item) => (
              <li className="our-capabilities__item" key={`Section 3 ${item}`}>
                {item}
              </li>
            ))}
          </ol>
        </div>

        <div className="our-capabilities__section">
          <h3 className="our-capabilities__section-title">{section4Title}</h3>
          <ol className="our-capabilities__section-items">
            {section4Items.map((item) => (
              <li className="our-capabilities__item" key={`Section 4 ${item}`}>
                {item}
              </li>
            ))}
          </ol>
        </div>
      </div>
    </section>
  );
};

export default memo(OurCapabilties);
