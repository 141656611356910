import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import TripleImage from '../components/TripleImage';
import Layout from '../components/Layout';
import ProjectGrid from '../components/ProjectGrid';
import SEO from '../components/SEO';
import TextSplit from '../components/TextSplit';
import FullWidthVideo from '../components/FullWidthVideo';
import DescriptorPanel from '../components/DescriptorPanel';
import Spacer from '../components/Spacer';
import HorizontalSlider from '../components/HorizontalSlider';
import ClientLogosGrid from '../components/ClientLogosGrid';
import OurCapabilities from '../components/OurCapabilities';
import HomeHero from '../components/HomeHero';

const Index = () => {
  const data = useStaticQuery(graphql`
    query HomePageQuery {
      content: contentfulHomePage {
        metaTitle
        metaDescription
        heroTitle
        heroParagraph {
          heroParagraph
        }
        descriptorPanelTitle
        descriptorPanelLink
        descriptorPanelLinkLabel
        productFeatures {
          title
          text {
            text
          }
        }
        productFeaturesLink {
          label
          url
        }
        splitPanelTitle
        splitPanelText {
          splitPanelText
        }
        splitPanelImage {
          alt: description
          fluid(maxWidth: 950, quality: 90) {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
        tripleImage1 {
          alt: description
          fluid(maxWidth: 750, quality: 90) {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
        tripleImage2 {
          alt: description
          fluid(maxWidth: 750, quality: 90) {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
        tripleImage3 {
          alt: description
          fluid(maxWidth: 680, quality: 95) {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
        footerPromo
        footerPromoTheme
      }
      projects: contentfulWorkPage {
        projects {
          slug
          title
          formattedTitle
          textColour: gridTheme
          client
          category
          homepageFeatured
          image: gridImage {
            fluid(maxWidth: 1440, quality: 95) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
          imageHover: gridImageHover {
            fluid(maxWidth: 1440, quality: 95) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
      }
    }
  `);

  const {
    content: {
      metaTitle,
      metaDescription,
      heroTitle,
      heroParagraph: { heroParagraph },
      descriptorPanelTitle,
      descriptorPanelLink,
      descriptorPanelLinkLabel,
      productFeatures,
      productFeaturesLink,
      splitPanelTitle,
      splitPanelText: { splitPanelText },
      splitPanelImage,
      tripleImage1,
      tripleImage2,
      tripleImage3,
      footerPromo,
      footerPromoTheme,
    },
    projects: { projects },
  } = data;

  const featuredProjects = projects.filter(
    (project) => project.homepageFeatured
  );

  const firstFeaturedProjects = featuredProjects.slice(0, 2);
  const secondFeaturedProjects = featuredProjects.slice(2, 4);

  return (
    <Layout
      headerTheme="dark"
      footerPromo={footerPromo}
      footerPromoTheme={footerPromoTheme}
    >
      <SEO title={metaTitle} description={metaDescription} />
      <HomeHero title={heroTitle} text={heroParagraph} />
      <ProjectGrid projects={firstFeaturedProjects} home={true} flip={true} />
      <DescriptorPanel
        title={descriptorPanelTitle}
        link={descriptorPanelLink}
        linkLabel={descriptorPanelLinkLabel}
        home={true}
      />
      {productFeatures.length > 1 && (
        <>
          <Spacer variant="home2" />

          <HorizontalSlider
            panels={productFeatures}
            link={productFeaturesLink}
          />
          <Spacer variant="home1" />
        </>
      )}
      <ProjectGrid projects={secondFeaturedProjects} home={true} number={2} />
      <ClientLogosGrid page="home" />
      <Spacer variant="home2" />
      <OurCapabilities />
      <Spacer variant="home3" />
      <FullWidthVideo />
      <TextSplit
        title={splitPanelTitle}
        text={splitPanelText}
        image={splitPanelImage}
        linkText="Our experts"
        link="/people"
        linkAlign="left"
        theme="white"
        flipped={true}
      />
      <TripleImage
        image1={tripleImage1}
        image2={tripleImage2}
        image3={tripleImage3}
      />
    </Layout>
  );
};

export default Index;
