import React, { useEffect, useRef, useState } from 'react';

import classnames from 'classnames';
import { useInView } from 'react-hook-inview';
import { useMediaQuery } from 'react-responsive';

import MuteIcon from '../../assets/images/site/unmuted-icon.svg';
import MutedIcon from '../../assets/images/site/muted-icon.svg';

const FullWidthVideo = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 550px)' }, undefined);
  const startScale = isMobile ? 0.35 : 0.55;
  const maxScale = 1;

  let refEl;
  const videoRef = useRef();

  const [posterFile, setPosterFile] = useState('');
  const [videoFile, setVideoFile] = useState('');
  const [isMuted, setIsMuted] = useState(true);
  const [isHovered, setIsHovered] = useState(true);
  const [videoScale, setVideoScale] = useState(startScale);
  const [fullyScaled, setFullyScaled] = useState(false);

  useEffect(() => {
    const videoEl = videoRef.current;
    videoEl && videoEl.pause();
    setPosterFile(`showreel-poster--${isMobile ? 'mobile' : 'desktop'}`);
    setVideoFile(`showreel--${isMobile ? 'mobile' : 'desktop'}`);
    videoEl && videoEl.load();
    videoEl && videoEl.play();
  }, [isMobile, videoRef]);

  [refEl] = useInView({
    threshold: 0.1,
    unobserveOnEnter: false,
    onEnter: () => {
      if (window && !fullyScaled) {
        const { scrollY: startScroll, innerHeight: windowHeight } = window;
        const scrollThreshold = (windowHeight / 2) * 1.5;
        const scaleVal = (maxScale - startScale) / scrollThreshold;

        const handleScroll = () => {
          const { scrollY } = window;
          const scrolledBy = -1 * (startScroll - scrollY);

          if (scrolledBy < 0) {
            setVideoScale(maxScale);
            setFullyScaled(true);
            window.removeEventListener('scroll', handleScroll);
          }

          const newScale = startScale + scrolledBy * scaleVal;
          const restrainedNewScale = Math.min(
            Math.max(newScale, startScale),
            maxScale
          );

          setVideoScale(restrainedNewScale);

          if (restrainedNewScale === maxScale) {
            setFullyScaled(true);
          }

          if (scrolledBy > scrollThreshold) {
            window.removeEventListener('scroll', handleScroll);
          }
        };

        window.addEventListener('scroll', handleScroll);
      }
    },
  });

  if (!posterFile || !videoFile) {
    return null;
  }

  return (
    <section
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="full-width-video"
      ref={refEl}
      role="presentation"
    >
      <div
        className="full-width-video__video-wrap"
        style={{
          transform: `translateX(-50%) scale(${
            fullyScaled ? maxScale : videoScale
          })`,
        }}
      >
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video
          className="full-width-video__video"
          ref={videoRef}
          width="100%"
          loop={true}
          autoPlay
          muted={isMuted}
          volume={isMuted ? 0 : 1}
          preload="metadata"
          playsInline
          disablePictureInPicture
          poster={`https://wreel.agency/video/${posterFile}.jpg`}
        >
          <source
            src={`https://wreel.agency/video/${videoFile}.mp4`}
            type="video/mp4"
          />
          <source
            src={`https://wreel.agency/video//${videoFile}.webm`}
            type="video/webm"
          />
        </video>
      </div>
      <button
        className={classnames('full-width-video__mute-toggle', {
          'full-width-video__mute-toggle--hidden':
            (!isHovered && !isMuted) || !fullyScaled,
        })}
        onClick={() => setIsMuted(!isMuted)}
        aria-label={isMuted ? 'Unmute video' : 'Mute video'}
      >
        {isMuted ? (
          <MutedIcon className="full-width-video__mute-icon" />
        ) : (
          <MuteIcon className="full-width-video__mute-icon" />
        )}
      </button>
    </section>
  );
};

export default FullWidthVideo;
