import React, { useEffect, useRef } from 'react';

import getFormattedText from '../../utils/getFormattedText';

import AnimateFormatted from '../AnimateFormatted';
import FadeUpIntoView from '../FadeUpIntoView';

import LinkButton from '../LinkButton';
import DownArrow from '../../assets/images/site/arrow-down.svg';

const HomeHero = ({ title, text }) => {
  const videoRefDesktop = useRef();
  const videoRefMobile = useRef();

  const url = 'https://wreel.agency/video';

  const mobileVideo = 'homepage__mobile';
  const desktopVideo = 'homepage__desktop';

  useEffect(() => {
    videoRefMobile.current.play();
    videoRefDesktop.current.play();
  }, [videoRefDesktop.current, videoRefMobile.current]);

  return (
    <section className="home-hero">
      <div className="home-hero__main panel panel--header hero-panel">
        <div className="home-hero__content">
          <h1 className="home-hero__title">
            <AnimateFormatted on="load" text={title} />
          </h1>

          <div className="home-hero__button-wrap home-hero__button-wrap--primary">
            <LinkButton
              text="Get in touch"
              link="/contact/"
              extraClass="home-hero__button home-hero__button--primary"
              theme="outline"
              hasArrow={true}
            />
          </div>

          <a href="#main" className="home-hero__down-arrow">
            <DownArrow />
          </a>
        </div>

        <video
          className="home-hero__video home-hero__video--mobile"
          autoPlay={false}
          loop={true}
          muted={true}
          volume={0}
          preload="metadata"
          disablePictureInPicture
          playsInline
          poster={`${url}/${mobileVideo}.png`}
          ref={videoRefMobile}
        >
          <source src={`${url}/${mobileVideo}.mp4`} type="video/mp4" />
          <source src={`${url}/${mobileVideo}.webm`} type="video/webm" />
        </video>

        <video
          className="home-hero__video home-hero__video--desktop"
          autoPlay={false}
          loop={true}
          muted={true}
          volume={0}
          preload="metadata"
          disablePictureInPicture
          playsInline
          poster={`${url}/${desktopVideo}.png`}
          ref={videoRefDesktop}
        >
          <source src={`${url}/${desktopVideo}.mp4`} type="video/mp4" />
          <source src={`${url}/${desktopVideo}.webm`} type="video/webm" />
        </video>
      </div>
      <div className="home-hero__secondary panel panel--intro" id="main">
        <FadeUpIntoView>
          <h2 className="home-hero__subtitle">{getFormattedText(text)}</h2>
        </FadeUpIntoView>

        <div className="home-hero__button-wrap home-hero__button-wrap--secondary">
          <LinkButton
            text="Case studies"
            link="/work/"
            extraClass="home-hero__button home-hero__button--secondary"
            theme="outline"
            hasArrow={true}
          />
        </div>
      </div>
    </section>
  );
};

export default HomeHero;
