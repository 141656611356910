import React, { memo } from 'react';

import SwipeUpImage from '../SwipeUpImage';

const TripleImage = ({ image1, image2, image3 }) => {
  return (
    <section className="triple-image">
      <SwipeUpImage
        image={image1}
        extraClass="triple-image__image triple-image__image--1"
      />
      <SwipeUpImage
        image={image2}
        extraClass="triple-image__image triple-image__image--2"
      />
      <SwipeUpImage
        image={image3}
        extraClass="triple-image__image triple-image__image--3"
      />
    </section>
  );
};

export default memo(TripleImage);
