import React from 'react';
import classnames from 'classnames';

import AnimateFormatted from '../AnimateFormatted';
import FadeUpIntoView from '../FadeUpIntoView';
import LinkButton from '../LinkButton';

const TextSplit = ({
  title,
  text,
  linkText,
  linkAlign,
  link,
  reducedLinkSpace,
  flipped,
  theme,
}) => {
  return (
    <section
      className={classnames(
        'panel',
        'panel--inset',
        'panel--mid-grey',
        'text-split',
        {
          [`text-split--${theme}`]: theme,
          [`text-split--has-button`]: linkText && link,
          'text-split--flipped': flipped,
        }
      )}
    >
      <div className="text-split__panels">
        <div className="text-split__panel text-split__panel--content">
          <FadeUpIntoView>
            <h2 className="text-split__title">
              <AnimateFormatted on="view" text={title} />
            </h2>
          </FadeUpIntoView>
          <FadeUpIntoView delayBy={4}>
            <p className="text-split__text">{text}</p>
          </FadeUpIntoView>
          {linkText && link && (
            <FadeUpIntoView>
              <LinkButton
                text={linkText}
                link={link}
                extraClass={classnames('text-split__button', {
                  [`text-split__button--reduced`]: reducedLinkSpace,
                })}
                hasArrow={true}
              />
            </FadeUpIntoView>
          )}
        </div>
      </div>
    </section>
  );
};

export default TextSplit;
